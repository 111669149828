<template>
  <DashboardTemplateCasual
    v-loading="loading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    :title="$t('message.setting')"
  >
    <h1>{{ $t("message.setting") }}</h1>
    <div>
      <h3>Survey id</h3>

      <el-divider></el-divider>
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item :label="$t('setting.casual')">
            <el-input
              v-model="formInline.casual"
              placeholder=""
              @change="handleInputSurvey"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('setting.period')">
            <el-input
              v-model="formInline.period"
              placeholder=""
              @change="handleInputSurvey"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <h3>{{ $t("setting.tableTest") }}</h3>

      <el-divider></el-divider>

      <table class="table table-striped">
        <thead class="thead-dark">
          <tr>
            <th scope="col">{{ $t("setting.name") }}</th>
            <th scope="col">Test id</th>
            <th scope="col">Time id</th>
            <th scope="col">{{ $t("setting.openToPlayInCasualMode") }}</th>
            <th scope="col">{{ $t("setting.openToPlayInPeriodMode") }}</th>
          </tr>
        </thead>
        <draggable v-model="tests" tag="tbody" @change="updateIndexs()">
          <tr v-for="item in tests" :key="item.name">
            <td>
              <font-awesome-icon icon="fa-solid fa-bars" /> {{ item.name }}
            </td>
            <td>
              <div
                class="input-label"
                :class="{ 'pd-b-4': item.code === 'word-recognition' }"
              >
                <span v-if="item.code === 'word-recognition'">EN: </span>
                <el-input
                  v-model="item.testKey"
                  placeholder=""
                  @input="(value) => handleInput(item._id, 'testKey', value)"
                ></el-input>
              </div>
              <div v-if="item.code === 'word-recognition'" class="input-label">
                <span>TH: </span>
                <el-input
                  v-model="item.testKey2"
                  placeholder=""
                  @input="(value) => handleInput(item._id, 'testKey2', value)"
                ></el-input>
              </div>
            </td>
            <td>
              <div class="pd-b-4">
                <el-input
                  v-model="item.timeKey"
                  placeholder=""
                  @input="(value) => handleInput(item._id, 'timeKey', value)"
                ></el-input>
              </div>
              <div v-if="item.code === 'word-recognition'">
                <el-input
                  v-model="item.timeKey2"
                  placeholder=""
                  @input="(value) => handleInput(item._id, 'timeKey2', value)"
                ></el-input>
              </div>
            </td>
            <td class="center">
              <el-switch
                v-model="item.casualAllow"
                @change="(value) => handleInput(item._id, 'casualAllow', value)"
                active-color="#13ce66"
                inactive-color="#a6a5aa"
              >
              </el-switch>
            </td>
            <td class="center">
              <el-switch
                v-model="item.periodAllow"
                @change="(value) => handleInput(item._id, 'periodAllow', value)"
                active-color="#13ce66"
                inactive-color="#a6a5aa"
              >
              </el-switch>
            </td>
          </tr>
        </draggable>
      </table>
    </div>
  </DashboardTemplateCasual>
</template>

<script>
import DashboardTemplateCasual from "@/template/DashboardTemplateCasual";
import draggable from "vuedraggable";
import { HTTP } from "@/service/axios";

export default {
  components: {
    DashboardTemplateCasual,
    draggable,
  },
  data() {
    return {
      loading: false,
      tests: [],
      formInline: {
        casual: "",
        period: "",
      },
      debounce: null,
      debounceSurvey: null,
    };
  },
  watch: {
    "$i18n.locale"() {
      this.$store.commit("SET_BREADCRUMBS", [
        { path: "/", name: "home", i18n: true },
        { path: null, name: this.$t("message.setting") },
      ]);
    },
  },
  mounted() {
    this.$store.commit("SET_BREADCRUMBS", [
      { path: "/", name: "home", i18n: true },
      { path: null, name: this.$t("message.setting") },
    ]);

    this.fetchTests();
    this.fetchSurvey();
  },
  destroyed() {
    this.$store.commit("SET_BREADCRUMBS", []);
  },
  methods: {
    async fetchTests() {
      try {
        let res = await HTTP.get(`/tests/count/level`);

        if (res.data.success) {
          this.tests = res.data.result.rows;
        }
      } catch (e) {
        const error = e.response;
        console.error(error);
      }
    },
    async updateIndexs() {
      try {
        let tests = [];
        this.tests.map((item, index) => {
          tests.push({
            _id: item._id,
            number: index + 1,
          });
        });

        let obj = {
          tests: tests,
        };

        await HTTP.put(`/tests/update/number`, obj);
      } catch (e) {
        console.error(e);
      }
    },
    handleInput(id, key, value) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let update = {
          testId: id,
          data: {},
        };

        update.data[key] = value;
        this.updateTest(update);
      }, 600);
    },
    async updateTest(update) {
      try {
        await HTTP.put(`/tests/${update.testId}`, update.data);
      } catch (e) {
        console.error(e);
      }
    },
    handleInputSurvey() {
      clearTimeout(this.debounceSurvey);
      this.debounceSurvey = setTimeout(() => {
        this.updateSurvey();
      }, 600);
    },
    async fetchSurvey() {
      try {
        let res = await HTTP.get(`/settings/type/survey`);

        if (res.data.success) {
          this.formInline = {
            casual: res.data.result.survey.casual,
            period: res.data.result.survey.period,
          };
        }
      } catch (e) {
        console.error(e);
      }
    },
    async updateSurvey() {
      try {
        let obj = {
          type: "survey",
          survey: {
            casual: this.formInline.casual,
            period: this.formInline.period,
          },
        };
        await HTTP.put(`/settings`, obj);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>


<style scoped lang="scss">
@import "../styles/element-variables.scss";
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;

  thead {
    th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
    }

    &.thead-dark {
      th {
        color: #fff;
        background-color: $--color-primary;
        border-color: $--color-primary;
      }
    }
  }

  td,
  th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  th {
    text-align: center;
  }

  td {
    cursor: move;
    vertical-align: middle;
    &.center {
      text-align: center;
    }
  }

  &.table-striped {
    tbody {
      tr:nth-of-type(odd) {
        background-color: $--color-blue-95;
      }
    }
  }

  .input-label {
    display: flex;
    align-items: center;
    span {
      padding-right: 5px;
    }
  }
}
</style>